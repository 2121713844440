import { useQuery } from '@tanstack/vue-query';
import type { MaybeRefOrGetter } from 'vue';

import { getLoggedInCustomerInformation } from '@/api/services/customer-portal-service';
import { queryClient } from '@/plugins/vue-query-plugin';
import type { IAuthCustomer } from '@/types';
import type { IResponse } from '@/types/api';
import type { Maybe } from '@/types/utility-types';

export function useLoggedInCustomer(enabled: MaybeRefOrGetter<boolean>) {
  return useQuery({
    queryKey: ['customer'],
    queryFn: () => getLoggedInCustomerInformation(),
    enabled,
  });
}

export function prefillLoggedInCustomerQuery(loggedInCustomer: Maybe<IResponse<IAuthCustomer>>) {
  queryClient.setQueryData(['customer'], loggedInCustomer);
}

export function invalidateLoggedInCustomerQuery() {
  return queryClient.invalidateQueries({ queryKey: ['customer'] });
}
